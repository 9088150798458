import { TrackJS } from 'trackjs';

export function useMonitoring() {
  const nuxtApp = useNuxtApp();
  const enableDev = true;
  const consoleLogOnDev = true;
  const includeFeatureFlags = true;
  const config = useRuntimeConfig();

  async function trackError(errorLabel: string, errorContext: object | undefined = undefined) {
    const context = {
      context: errorContext,
      featureFlags: includeFeatureFlags ? await nuxtApp.$getAllFeatureFlags() : {},
    };
    if (enableDev || !import.meta.env.DEV) {
      TrackJS.console.error(errorLabel, context);
      if (config.public.datadogEnabled) {
        const datadogRum = (await import('@datadog/browser-rum')).datadogRum;

        datadogRum.addError(errorLabel, context);
      }
    }
    if (consoleLogOnDev && import.meta.env.DEV) {
      console.log(errorLabel, context);
    }
  }

  async function trackAction(actionName: string, actionContext: object | undefined = undefined) {
    if (!config.public.datadogEnabled) return;

    const context = {
      context: actionContext,
      featureFlags: includeFeatureFlags ? await nuxtApp.$getAllFeatureFlags() : {},
    };
    try {
      const datadogRum = (await import('@datadog/browser-rum')).datadogRum;

      datadogRum.addAction(actionName, {
        context: context,
      });
    } catch (error: any) {
      trackError(error);
    }
  }

  function trackGraphglError(query: string, payload: object, response: object) {
    trackError(`GraphQL: ${query} failed`, {
      payload: payload,
      response: response,
    });
  }

  return { trackError, trackGraphglError, trackAction };
}
